import React from "react";

const ComingSoonPage = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-r from-blue-600 to-cyan-500 text-white">
      <div className="text-center max-w-md">
        <h1 className="text-5xl font-bold mb-5">X-Express</h1>
        <h3 className="text-xl mb-6" style={{ color: '#414141' }}>
          We're launching something exciting very soon! Stay tuned.
        </h3>
        <br/>
        <a href="https://xexpress.news/" target="_blank" rel="noopener noreferrer">
          <button
            className="px-2 py-1 text-lg rounded-lg bg-blue-800 text-white hover:bg-blue-700 transition"
            style={{ padding: '7px', borderRadius: '10px', background: '#002f6a', color: 'white' }}
          >
            Open New Tab
          </button>
        </a>


        <div className="mt-5 flex justify-center space-x-4 text-2xl">
          <a href="#" className="hover:text-yellow-400">
            🔵
          </a>
          <a href="#" className="hover:text-yellow-400">
            📸
          </a>
          <a href="#" className="hover:text-yellow-400">
            🐦
          </a>
        </div>
      </div>
    </div>
  );
};

export default ComingSoonPage;
