import React,{Fragment,useContext, useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
//Import Components
import Slider from "react-slick";
import axios from "axios";
import { Row, Col, Card, Carousel,Accordion } from 'react-bootstrap'
import swal from "sweetalert";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
const Home = () => {


   const { BaseUrl } = "https://backoffice.xexpress.io/";
   const [homeCasino, setCasino] = useState([]);  

/*.....product code........*/
  const [loading, setLoading] = useState(true);
  const data = {
       key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
       }
  const APiResponse = () => {
      axios.post('/game-list',data)
      .then(response=>{
         setCasino(response.data.home_casino)
         setLoading(false); 
       })
   .catch(error=>{
         console.log(error)
     });
   }
    useEffect(()=>{
   const interval = setInterval(
      (function callApi() {
        APiResponse();
        return callApi;
      })(),
      70000000
    );
    return () => clearInterval(interval);
    },[]);




return(
     <>

<span className="d-xl-block">
  <div className="row">
    <Col xl="12" className="pl-1 mt-2">
      <div className="row g-3">
        {homeCasino.map((ele) => (
          <Col key={ele.id} xl="6" lg="6" md="6" sm="6" xs="12" className="p-1">
            <Link to={"/casino-game/"+ele.id+"/"+ele.slug} className="position-relative d-block games-main text-decoration-none">
              <div className="card shadow-lg border-0 h-100">
                <div className="card-img-top position-relative">
                  <img src={ele.image} alt="" className="img-fluid rounded w-100" />
                </div>
                <div className="card-body text-center">
                  <h2 className="h5 mb-1 text-primary">{ele.name}</h2>
                  <button className="btn btn-sm btn-primary mt-2">Play Now</button>
                </div>
              </div>
            </Link>
          </Col>
        ))}
      </div>
    </Col>
  </div>
</span>

    </>
  )
}
export default Home;