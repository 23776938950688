import React, { Fragment, useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import PageTitle from "../../../layouts/PageTitle";
import axios from "axios";
import swal from "sweetalert";

const Checkout = () => {
  const [depositInput, setDepositInput] = useState({
    wallet_add: '',
    accountType: 'XEXG', 
    password: '',
    error_list: [],
  });

  const [loading, setLoading] = useState(false);
  const [income, setIncome] = useState([]); 

  const handleInput = (e) => {
    setDepositInput({ ...depositInput, [e.target.name]: e.target.value });
  };

  const fetchIncomeData = () => {
    const selectedCurrency = localStorage.getItem('selectedCurrency');
    const auth_token = localStorage.getItem('auth_token');
    const data = {
      key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
      currency: selectedCurrency,
    };

    if (auth_token !== null) {
      axios.post('/get-method', data)
        .then(response => {
          setIncome(response.data.data);
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    fetchIncomeData();
  }, []);

  const depositSubmit = (e) => {
    e.preventDefault();
    const auth_token = localStorage.getItem('auth_token');
    const data = {
      wallet_add: depositInput.wallet_add,
      accountType: depositInput.accountType,
      password: depositInput.password,
      key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
    };

    if (auth_token !== null && !loading) {
      setLoading(true);
      axios.post(`/sub-method`, data)
        .then(res => {
          if (res.data.status === 'success') {
            swal("Success", res.data.message, "success");
            setDepositInput({
              wallet_add: '',
              accountType: 'XEXG',
              password: '',
              error_list: [],
            });

            // Fetch updated data after a successful form submission
            fetchIncomeData();
          } else if (res.data.status === 'fail') {
            swal("Warning", res.data.message, "warning");
          } else {
            setDepositInput({ ...depositInput, error_list: res.data.validation_error });
          }
          setLoading(false);
        })
        .catch(() => setLoading(false));
    }
  };

  return (
    <Fragment>
      <div className="row">
        <div className="col-xl-12">
          <div className="card">
            <div className="card-body">
              <h4 className="mb-3">Withdrawal Method</h4>
              <form onSubmit={depositSubmit} className="needs-validation">
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="amount">Crypto Address</label>
                    <input
                      type="text"
                      className="form-control"
                      id="wallet_address"
                      name="wallet_add"
                      placeholder="Crypto Wallet Address"
                      onChange={handleInput}
                      value={depositInput.wallet_add}
                    />
                    <span className="text-danger">{depositInput.error_list.amount}</span>
                  </div>

                  <div className="col-md-6 mb-3">
                    <label className="d-block mb-2">Select Method</label>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="accountType"
                        id="trc_20"
                        value="trc_20"
                        onChange={handleInput}
                        checked={depositInput.accountType === 'trc_20'}
                      />
                      <label className="form-check-label" htmlFor="trc_20">
                        USDT TRC-20
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="accountType"
                        id="bep_20"
                        value="bep_20"
                        onChange={handleInput}
                        checked={depositInput.accountType === 'bep_20'}
                      />
                      <label className="form-check-label" htmlFor="bep_20">
                        USDT BEP-20
                      </label>
                    </div>
                    <span className="text-danger">{depositInput.error_list.accountType}</span>
                  </div>

                  <div className="col-md-12 mb-3">
                    <label htmlFor="firstName">Account Password</label>
                    <input
                      type="password"
                      className="form-control"
                      id="firstName"
                      placeholder="Password"
                      required
                      name="password"
                      onChange={handleInput}
                      value={depositInput.password}
                    />
                    <span className="text-danger">{depositInput.error_list.password}</span>
                  </div>
                </div>

                <hr className="mb-4" />
                <button
                  className="btn btn-warning btn-lg btn-block"
                  type="submit"
                  disabled={loading}
                >
                  {loading ? 'Submitting...' : 'Update Method'}
                </button>
              </form>

              <div className="card-body">
                <div className="table-responsive">
                  <table className="display w-100 dataTable" id="example5">
                    <thead>
                      <tr>
                        <th>Wallet Chain</th>
                        <th>Wallet Address</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {income.map((row) => (
                        <tr key={row.id}>
                          <td>{row.wallet_chain}</td>
                          <td>{row.wallet_address}</td>
                          <td>{row.create_time}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Checkout;
