import React, { Fragment,useState,useEffect } from "react";
import { Form } from "react-bootstrap";
import PageTitle from "../../../layouts/PageTitle";
import { useParams } from "react-router-dom";
import axios from "axios";
import swal from "sweetalert";

const Checkout = () => {


const [amounta, setAmount] = useState(25);

const handleIncrement = () => {
  setAmount(prevAmount => prevAmount + 25);
  setDepositInput(prevState => ({
    ...prevState,
    amount: prevState.amount + 25,
  }));
};

const handleDicrement = () => {
  setAmount(prevAmount => Math.max(prevAmount - 25, 25)); // Ensure the minimum value is 25
  setDepositInput(prevState => ({
    ...prevState,
    amount: Math.max(prevState.amount - 25, 25),
  }));
};


const [depositInput, setDepositInput] = useState({
  amount: amounta,
  password: '',
  error_list: [],
});
const handleInput = (e) => {
  setDepositInput({...depositInput, [e.target.name]: e.target.value });
};


const [loading, setLoading] = useState(false);
const depositSubmit = (e) => {
  e.preventDefault();
  const auth_token = localStorage.getItem('auth_token');
  const data = {
      amount: amounta,
      password: depositInput.password,
      key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9'
  }
 if (auth_token !== null && !loading) {
    setLoading(true);
    axios.post(`/sub-invest`, data).then(res => {
       if(res.data.status === 'success')
       {
         swal("Success",res.data.message,"success");
         setDepositInput({...depositInput,
           amount:'',
           password:'',
           
         });
       }
       else if(res.data.status === 'fail')
       {
         swal("Warning",res.data.message,"warning");
       }
       else 
       {
       setDepositInput({...depositInput, error_list: res.data.validation_error});
       }
       setLoading(false);
   });
 }
}

   return (
      <Fragment>
         <div className="row">
            <div className="col-xl-12">
               <div className="card">
                  <div className="card-body">
                     <div className="row">
                        <div className="col-md-4 order-md-2 mb-4">
                           <h4 className="d-flex justify-content-between align-items-center mb-3">
                              <span className="text-muted">Join Community</span>
                              <span className="badge badge-primary badge-pill">
                                $
                              </span>
                           </h4>
                           <ul className="list-group mb-3">
                              <li className="list-group-item d-flex justify-content-between lh-condensed">
                                 <div>
                                    <h6 className="my-0">Community security</h6>
                                    <small className="text-muted">
                                        Ensuring a Safe and Secure Community
                                    </small>
                                 </div>
                                 <span className="text-muted">100%</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between lh-condensed">
                                 <div>
                                    <h6 className="my-0">Community Amount</h6>
                                    <small className="text-muted">
                                       Community payment
                                    </small>
                                 </div>
                                 <span className="text-muted">${depositInput.amount}</span>
                              </li>
                              <li className="list-group-item d-flex justify-content-between lh-condensed">
                                 <div>
                                    <h6 className="my-0">Count Community</h6>
                                    <small className="text-muted">
                                       Count payment
                                    </small>
                                 </div>
                                 <span className="text-muted">{depositInput.amount / 25}</span>
                              </li>
            
                              <li className="list-group-item d-flex justify-content-between lh-condensed">
                                 <div>
                                    <small className="text-muted">
                                       Our Community
                                    </small>
                                    <h6 className="my-0">Join Our Vibrant Community Today! </h6>
                                 </div>
                              </li>
                           </ul>
                        </div>
                        <div className="col-md-8 order-md-1">
                           <h4 className="mb-3">Join Community </h4>
                           <form onSubmit={depositSubmit} className="needs-validation" noValidate="">
                              <div className="row">
                                 <div className="col-md-6 mb-3">
                                   <label htmlFor="amount">Amount</label>
                                   <div className="input-group">
                                     <button className="btn btn-primary" type="button" onClick={handleDicrement}>
                                       <li className="fa fa-minus color-info"></li>
                                     </button>
                                          <input
                                             type="text"
                                             className="form-control"
                                             id="amount"
                                             name="amount"
                                             placeholder="Amount"
                                             value={amounta}
                                             readOnly
                                          />

                                     <button className="btn btn-primary" type="button" onClick={handleIncrement}>
                                       <li className="fa fa-plus color-info"></li>
                                     </button>
                                   </div>
                                   <span className="text-danger">{depositInput.error_list.amount}</span>
                                 </div>

                                 <div className="col-md-6 mb-3">
                                    <label htmlFor="firstName">
                                       Account Password
                                    </label>
                                    <input
                                       type="password"
                                       className="form-control"
                                       id="firstName"
                                       placeholder="Password"
                                       required
                                       name="password"
                                       onChange={handleInput} 
                                       value={depositInput.password}
                                    />
                                       <span className="text-danger">{depositInput.error_list.password}</span>
                                    
                                 </div>
                              </div>

                              <hr className="mb-4" />
                              <div className="form-check custom-checkbox mb-2">
                                 <input
                                    type="checkbox"
                                    className="form-check-input"
                                    id="same-address"
                                    required

                                 />
                                 <label
                                    className="form-check-label"
                                    htmlFor="same-address"
                                 >
                                    Accept treams & condition
                                 </label>
                              </div>
                              <hr className="mb-4" />
                              <button
                                 className="btn btn-warning btn-lg btn-block"
                                 type="submit"
                                 disabled={loading}
                              >
                                {loading ? 'Submitting...' : 'Join Community'}
                              </button>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default Checkout;
