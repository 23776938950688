import React,{Fragment,useContext, useEffect,useState} from 'react';
import {Link} from 'react-router-dom';
//Import Components
import loading_img from "../../../../images/profile/loading.gif";
import { GlobalSet } from '../../global/GlobalProvider';
import Coin_Listing from "../../../../images/icon/Coin_Listing.png";
import Slider from "react-slick";
import axios from "axios";
import { Row, Col, Carousel,Accordion } from 'react-bootstrap'
import swal from "sweetalert";
const Home = () => {
  document.title = "X-Express Home";
  const { BaseUrl } = GlobalSet();
  const [tradestatus, setTradeStatus] = useState([]);
  const [tracoinvalue, setCoinvalue] = useState([]);
  let incrementInterval;
  const [loading, setLoading] = useState(true);

   const [homedata, setHomeData] = useState([]);  
   const coin_wait = localStorage.getItem('coin_wait');
  /*.....product code........*/
  const data = {
       key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
       }
  const APiResponse = () => {
      axios.post('/home-data',data)
      .then(response=>{
         setHomeData(response.data.data_info)
         setTradeStatus(response.data.trade_status)
         setCoinvalue(response.data.coin_value)
         setLoading(false); 
       })
   .catch(error=>{
         console.log(error)
     });
   }
    useEffect(()=>{
   const interval = setInterval(
      (function callApi() {
        APiResponse();
        return callApi;
      })(),
      70000000
    );
    return () => clearInterval(interval);
    },[]);

return(
     <>
      <span style={{  marginTop: '-36px' }} className="d-xl-block">

        <div className="row">
            <Fragment>

           <Col xl="12" className="p-0" >
                <div className="col-xl-12">
                  <div className="row mydivpadding">


                    <div className="col-xl-12 col-xxl-12 col-12  mt-3">
                      <div style={{ background : '#002f6a'}} className="card booking">
                        <div className="card-body">
                            <h4 className="text-center text-white">1 XEXG = ${ coin_wait } </h4>
                            <button
                              className={`btn btn-sm btn-block ${homedata.income_status === 1 ? 'btn-success' : 'btn-warning'}`}
                              type="submit"
                            >
                              {homedata.income_status === 1 ? 'Active' : 'Inactive'}
                            </button>                         
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking" style={{background : '#002f6a'}}>
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <span>
                              <img 
                                src={BaseUrl+"public/basic_icon/asset_main_wallet.png"} 
                                alt="hover effect" 
                                 width="28"
                                height="20"
                              />
                             </span>
                            <div className="ms-4">
                              <h2 className="mb-0 font-w600 text-white">${ homedata.balance }</h2>
                              <p className="mb-0 text-nowrap text-white">Main Wallet</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking" style={{background : '#002f6a'}}>
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <span>
                              <img 
                                src={BaseUrl+"public/basic_icon/asset_deposit_wallet.png"} 
                                alt="hover effect" 
                                 width="28"
                                height="20"
                              />
                             </span>
                            <div className="ms-4">
                              <h2 className="mb-0 font-w600 text-white">${ parseFloat(parseFloat(homedata.reserve_balance).toFixed(4))  }</h2>
                              <p className="mb-0 text-nowrap text-white ">Deposit Wallet</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking" style={{background : '#002f6a'}}>
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <span>
                              <img 
                                src={BaseUrl+"public/basic_icon/asset_oin_comonity.png"} 
                                alt="hover effect" 
                                 width="28"
                                height="20"
                              />
                              </span>
                            <div className="ms-4">
                              <h2 className="mb-0 font-w600 text-white">${ homedata.investing_wallet }  <span style={{fontSize: '10px'}}> { parseFloat(parseFloat(homedata.investing_wallet / 25).toFixed(0))} </span></h2>
                              <p className="mb-0 text-nowrap text-white ">Join Community</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking" style={{background : '#002f6a'}}>
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <span>
                              <img 
                                src={Coin_Listing} 
                                alt="hover effect" 
                                 width="28"
                                height="20"
                              />
                              </span>
                            <div className="ms-4">
                              <h2 className="mb-0 font-w600 text-white" style={{ background: 'radial-gradient(#e23428, #002f6a)', color: 'white', textAlign: 'center', borderRadius: '20px' }} >{ homedata.investing_node } </h2>
                              <p className="mb-0 text-nowrap text-white ">Count Node</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  {/* <div className="col-xl-3 col-xxl-3 col-6">
                        <div className="card booking">
                          <div className="card-body">
                            <div style={{ textAlign: 'center' }}>
                              <img alt="images" width="28" height="28" src="" />
                              <h2 className="mb-0 font-w600 text-white">$6.90777</h2>
                              <p className="mb-0 text-nowrap text-white">Main Wallet</p>
                            </div>
                          </div>
                        </div>
                      </div>*/}

                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking" style={{background : '#002f6a'}}>
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <span>
                              <img 
                                src={BaseUrl+"public/basic_icon/asset_ref_bounus.png"} 
                                alt="hover effect" 
                                 width="28"
                                height="20"
                              />
                            </span>
                            <div className="ms-4">
                              <h2 className="mb-0 font-w600 text-white">${ homedata.refer_inc }</h2>
                              <p className="mb-0 text-white"> Refer Bonus </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking" style={{background : '#002f6a'}}>
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <span>
                              <img 
                                src={BaseUrl+"public/basic_icon/asset_XEXG.png"} 
                                alt="hover effect" 
                                 width="28"
                                height="20"
                              />
                            </span>
                            <div className="ms-4">
                              <h2 className="mb-0 font-w600 text-white"> { parseFloat(parseFloat(homedata.coin).toFixed(2))} <span style={{fontSize: '8px'}}> XEXG </span></h2>
                              <p className="mb-0 text-white">${  parseFloat(parseFloat(tracoinvalue).toFixed(5)) }</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                   <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking" style={{background : '#002f6a'}}>
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <span>
                              <img 
                                src={BaseUrl+"public/basic_icon/asset_XEXG_Genaration.png"} 
                                alt="hover effect" 
                                 width="28"
                                height="20"
                              />
                            </span>
                            <div className="ms-4">
                              <h2 className="mb-0 font-w600 text-white">{ homedata.coin_reserve }</h2>
                              <p className="mb-0 text-white">XEXG Reserve</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> 
                   <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking" style={{background : '#002f6a'}}>
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <span>
                              <img 
                                src={BaseUrl+"public/basic_icon/asset_XEXG_Genaration.png"} 
                                alt="hover effect" 
                                 width="28"
                                height="20"
                              />
                            </span>
                            <div className="ms-4">
                              <h2 className="mb-0 font-w600 text-white">{ homedata.coin_gen }</h2>
                              <p className="mb-0 text-white">XEXG Genaration</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> 
                   <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking" style={{background : '#002f6a'}}>
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <span>
                              <img 
                                src={BaseUrl+"public/basic_icon/asset_ref_bounus.png"} 
                                alt="hover effect" 
                                 width="28"
                                height="20"
                              />
                            </span>
                            <div className="ms-4">
                              <h2 className="mb-0 font-w600 text-white">$ { homedata.bord_earning }</h2>
                              <p className="mb-0 text-white">Bord Earning</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>                    

         

                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking" style={{background : '#002f6a'}}>
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <span>
                              <img 
                                src={BaseUrl+"public/basic_icon/asset_rank_income.png"} 
                                alt="hover effect" 
                                 width="28"
                                height="20"
                              />
                            </span>
                            <div className="ms-4">
                              <h2 className="mb-0 font-w600 text-white">${ homedata.leadership_bonus }</h2>
                              <p className="mb-0 text-white">  Leadership Bonus </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>                    

{/*                  <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking">
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <span>
                              <svg xmlns="http://www.w3.org/2000/svg"  width="28" height="28" viewBox="0 0 28 28" fill="none" stroke="#f5a623" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21.5 12H12V2.5"/><circle cx="12" cy="12" r="10"/></svg>                              

                            </span>
                            <div className="ms-4">
                              <h2 className="mb-0 font-w600 text-white">${ homedata.differential_bonuses }</h2>
                              <p className="mb-0">   Differential Bonuses </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking" style={{background : '#002f6a'}}>
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <span>
                              <img 
                                src={BaseUrl+"public/basic_icon/asset_rank_income.png"} 
                                alt="hover effect" 
                                 width="28"
                                height="20"
                              />
                            </span>
                            <div className="ms-4">
                              <h2 className="mb-0 font-w600 text-white">${ homedata.rank_income }</h2>
                              <p className="mb-0 text-white">  Rank Income </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking" style={{background : '#002f6a'}}>
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <span>
                              <img 
                                src={BaseUrl+"public/basic_icon/asset_total_deposit.png"} 
                                alt="hover effect" 
                                 width="28"
                                height="20"
                              />
                             </span>
                            <div className="ms-4">
                              <h2 className="mb-0 font-w600 text-white">${ homedata.total_deposit }</h2>
                              <p className="mb-0 text-white">Total Deposit</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking" style={{background : '#002f6a'}}>
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <span>
                              <img 
                                src={BaseUrl+"public/basic_icon/asset_Total_Income.png"} 
                                alt="hover effect" 
                                 width="28"
                                height="20"
                              />
                             </span>
                            <div className="ms-4">
                              <h2 className="mb-0 font-w600 text-white">${ homedata.total_Income }</h2>
                              <p className="mb-0 text-white">Total Income</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-3 col-xxl-3 col-6">
                      <div className="card booking" style={{background : '#002f6a'}}>
                        <div className="card-body">
                          <div className="booking-status d-flex align-items-center">
                            <span>
                              <img 
                                src={BaseUrl+"public/basic_icon/asset_Total_Withdrawal.png"} 
                                alt="hover effect" 
                                 width="28"
                                height="20"
                              />
                              </span>
                            <div className="ms-4">
                              <h2 className="mb-0 font-w600 text-white">${ homedata.total_withdraw }</h2>
                              <p className="mb-0 text-white">Total Withdrawal</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
             </Col>
          </Fragment>
        </div> 
      </span>
    </>
  )
}
export default Home;