import React, { Fragment,useState,useEffect } from "react";
import { Form } from "react-bootstrap";
import PageTitle from "../../../layouts/PageTitle";
import axios from "axios";
import swal from "sweetalert";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const Checkout = () => {
   const { t } = useTranslation();
   const [withdraw, setWithdraw] = useState([]);

    useEffect(()=>{
        const data = {
              key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
             }
        axios.post('/apps-withdraw',data)
        .then(response=>{
           setWithdraw(response.data.data)
           console.log(response.data.data)
           
       })
     .catch(error=>{
            
           console.log(error)
       })
     },[])


    const [depositInput, setDepositInput] = useState({
        amount: '',
        password: '',
        error_list: [],
    });

    const handleInput = (e) => {
        e.persist();
        setDepositInput({...depositInput, [e.target.name]: e.target.value });
    }

      const [income, setIncome] = useState([]); 

    const [loading, setLoading] = useState(false);
    const depositSubmit = (e) => {
        e.preventDefault();
        const auth_token = localStorage.getItem('auth_token');
        const data = {
            amount: depositInput.amount,
            password: depositInput.password,
            key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
           
        }

      if (auth_token !== null && !loading) {
          setLoading(true);
          axios.post(`/apps-withdraw-sub`, data).then(res => {
          setLoading(false);
                if(res.data.status === 'success')
                {
                   swal("Success",res.data.message,"success");
                  setDepositInput({...depositInput,
                    amount: '',
                    password: '',
                    
                  });
                }
                else if(res.data.status === 'fail')
                {
                  swal("Warning",res.data.message,"warning");
                }
                else 
                {
                setDepositInput({...depositInput, error_list: res.data.validation_error});
                }
              setLoading(false);
            });
          }

          }



  const fetchIncomeData = () => {
    const selectedCurrency = localStorage.getItem('selectedCurrency');
    const auth_token = localStorage.getItem('auth_token');
    const data = {
      key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
      currency: selectedCurrency,
    };

    if (auth_token !== null) {
      axios.post('/get-method', data)
        .then(response => {
          setIncome(response.data.data);
        })
        .catch(error => {
          console.error(error);
        });
    }
  };

  useEffect(() => {
    fetchIncomeData();
  }, []);


   return (
      <Fragment>
         <div className="row">
            <div className="col-xl-12">
               <div className="card">
                  <div className="card-body">
                     <div className="row">
                        <div className="col-md-12 order-md-1">
                           <h4 className="mb-3">{t('pro_with_withdraw')}</h4>
                           <form onSubmit={depositSubmit} className="needs-validation" noValidate="">
                              <div className="row">






              <div className="card-body">
                <div className="table-responsive">
                  <table className="display w-100 dataTable" id="example5">
                    <thead>
                      <tr>
                        <th>Wallet Chain</th>
                        <th>Wallet Address</th>
                        <th>Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {income.map((row) => (
                        <tr key={row.id}>
                          <td>{row.wallet_chain}</td>
                          <td>{row.wallet_address}</td>
                          <td>{row.create_time}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>


                                 <div className="col-md-12 mb-3">
                                    <label htmlFor="lastName">{t('pro_with_amount')}</label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       id="lastName"
                                       placeholder="Amount"
                                       required
                                       name="amount"
                                       onChange={handleInput} 
                                       value={depositInput.amount}
                                    />
                                       <span className="text-danger">{depositInput.error_list.amount}</span>
                                 </div>



{/*                                 <div className="col-md-12 mb-3">
                                    <label htmlFor="firstName">
                                       {t('pro_with_tr_number')}
                                    </label>
                                    <input
                                       type="text"
                                       className="form-control"
                                       id="firstName"
                                       placeholder=""
                                       required
                                       name="transaction_number"
                                       onChange={handleInput} 
                                       value={depositInput.transaction_number}
                                    />
                                    <span className="text-danger">{depositInput.error_list.transaction_number}</span>
                                    
                                 </div>*/}

                                 <div className="col-md-12 mb-3">
                                    <label htmlFor="firstName">
                                        {t('pro_with_password')}
                                    </label>
                                    <input
                                       type="password"
                                       className="form-control"
                                       id="firstName"
                                       placeholder=""
                                       required
                                       name="password"
                                       onChange={handleInput} 
                                       value={depositInput.password}
                                    />
                                   <span className="text-danger">{depositInput.error_list.password}</span>
                                    
                                 </div>
                              </div>

                              <hr className="mb-4" />
                              <button
                                 className="btn btn-warning btn-lg btn-block"
                                 type="submit"
                                 disabled={loading}
                              >
                                
                                 {loading ? 'Submitting...' : t('pro_with_withdraw')}
                              </button>
                           </form>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </Fragment>
   );
};

export default Checkout;
