import React, { useContext } from "react";

/// React router dom
import {  Switch, Route } from "react-router-dom";


/// Css
import "./index.css";
import "./chart.css";
import "./step.css";

/// Layout
import Nav from "./layouts/nav";
import Footer from "./layouts/Footer";
import ScrollToTop from "./layouts/ScrollToTop";
/// Dashboard
import Home from "./components/Dashboard/Home";

import Setting from "./layouts/Setting";

/////Demo
import Theme1 from "./components/Dashboard/Demo/Theme1";
import Theme2 from "./components/Dashboard/Demo/Theme2";


/// pages
import viewpage from "./components/AppsMenu/public/viewpage";

/// App
import Security from "./components/AppsMenu/AppProfile/Security";
import Menue from "./components/AppsMenu/AppProfile/menue";
import AppProfile from "./components/AppsMenu/AppProfile/AppProfile";
import AppAsset from "./components/AppsMenu/AppProfile/AppAsset";
import IncomeHistory from "./components/AppsMenu/AppProfile/IncomeHistory";
import TradingHistory from "./components/AppsMenu/AppProfile/TradingHistory";
import TransactionHistory from "./components/AppsMenu/AppProfile/TransactionHistory";
import WithdrawalHistory from "./components/AppsMenu/AppProfile/WithdrawalHistory";
import Affiliate from "./components/AppsMenu/AppProfile/Affiliate";
import MySmartTeam from "./components/AppsMenu/AppProfile/MySmartTeam";
import AllTeamUsers from "./components/AppsMenu/AppProfile/AllTeamUsers";
import Deposit from "./components/AppsMenu/AppProfile/Deposit";
import Trading from "./components/AppsMenu/AppProfile/trading";
import Node from "./components/AppsMenu/AppProfile/Node";
import Swap from "./components/AppsMenu/AppProfile/swap";
import Game from "./components/AppsMenu/AppProfile/Game";
import CasinoGetOne from "./components/Casino/CasinoGetOne";
import Migration from "./components/AppsMenu/AppProfile/migration";
import ConvertBalance from "./components/AppsMenu/AppProfile/ConvertBalance";
import BalanceTransfer from "./components/AppsMenu/AppProfile/BalanceTransfer";
import PrincipalMainWallet from "./components/AppsMenu/AppProfile/principalmainwallet";
import LocalDepositView from "./components/deposit/LocalDepositView";
import CryptoDepositView from "./components/deposit/CryptoDepositView";
import Withdraw from "./components/AppsMenu/AppProfile/Withdraw";
import WithdrawMethod from "./components/AppsMenu/AppProfile/WithdrawMethod";
import ChangePassword from "./components/AppsMenu/AppProfile/ChangePassword";
import ChangeTranjPassword from "./components/AppsMenu/AppProfile/ChangeTranjPassword";
import IdentityVerify from "./components/AppsMenu/AppProfile/IdentityVerify";
import SupportTicket from "./components/AppsMenu/AppProfile/SupportTicket";
import CreateTicket from "./components/AppsMenu/AppProfile/CreateTicket";
import ViewTicket from "./components/AppsMenu/AppProfile/ViewTicket";
import ViewTeam from "./components/AppsMenu/AppProfile/viewteam";
import ComingSoon from "./components/AppsMenu/AppProfile/Comingsoon";
import socialMedia from "./components/AppsMenu/AppProfile/socialMedia";

//currency-eur
import Eur from "./components/Currency/eur";
import Xau from "./components/Currency/xau";
import Gbp from "./components/Currency/gbp";
import Btc from "./components/Currency/btc";
import Eth from "./components/Currency/eth";
import Bnb from "./components/Currency/bnb";

    
import { ThemeContext } from "../context/ThemeContext";

const Markup = () => {
  const auth_token = localStorage.getItem('auth_token');
  const { menuToggle } = useContext(ThemeContext);

  const publicroutes = [
    { url: "ddd", component: Home }
  ];


  const authroutes = [
  /// Apps
    { url: "", component: Home },
    { url: "dashboard", component:  Home},
    { url: "view-page/:id/:titel", component: viewpage }, 
    { url: "menue", component: Menue },
    { url: "security", component: Security },
    { url: "app-profile", component: AppProfile },
    { url: "app-asset", component: AppAsset },
    { url: "trading-history", component: TradingHistory },
    { url: "income-history", component: IncomeHistory },
    { url: "transaction-history", component: TransactionHistory},
    { url: "withdrawal-history", component: WithdrawalHistory},
    { url: "affiliate", component: Affiliate},
    { url: "my-smart-team", component: MySmartTeam },
    { url: "all-team-users", component: AllTeamUsers },
    { url: "community", component: Trading },
    { url: "node", component: Node },
    { url: "swap", component: Swap },
    { url: "game", component: Game },
    { url: "casino-game/:id/:name", component: CasinoGetOne }, 
    { url: "migration", component: Migration },
    { url: "convert-balance", component: ConvertBalance },
    { url: "balance-transfer", component: BalanceTransfer },
    { url: "community-log", component: PrincipalMainWallet },
    { url: "deposit", component: Deposit },
    { url: "local-deposit-view/:id/:slug", component: LocalDepositView },
    { url: "crypto-deposit-view/:id/:slug", component: CryptoDepositView },
    { url: "withdraw", component: Withdraw },
    { url: "withdraw-method", component: WithdrawMethod },
    { url: "change-password", component: ChangePassword },
    { url: "change-transaction-password", component: ChangeTranjPassword },
    { url: "identity-verification", component: IdentityVerify },
    { url: "support-ticket", component: SupportTicket },
    { url: "create-ticket", component: CreateTicket },
    { url: "view-ticket/:id", component: ViewTicket },
    { url: "view-team/:id/:titel", component: ViewTeam }, 
    { url: "social-media", component: socialMedia },
    { url: "coming-soon", component: ComingSoon },



    { url: "currency-eur", component: Eur },
    { url: "currency-xau", component: Xau },
    { url: "currency-gbp", component: Gbp },
    { url: "currency-btc", component: Btc },
    { url: "currency-eth", component: Eth },
    { url: "currency-bnb", component: Bnb },
  ];

  if (auth_token !== null) {
   var routes = authroutes;
  }else{
   var routes = publicroutes;
  }

  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let pagePath = path.split("-").includes("page");
  return (
    <>
      <div
        id={`${!pagePath ? "main-wrapper" : ""}`}
        className={`${!pagePath ? "show" : "mh100vh"}  ${
          menuToggle ? "menu-toggle" : ""
        }`}
      >
        {!pagePath && <Nav />}

        <div style={{ marginTop: "30px",  marginBottom: "90px"}}>
          <div
            className={`${!pagePath ? "container-fluid" : ""}`}
            style={{ minHeight: window.screen.height - 60 }}
          >
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}
            </Switch>
          </div>
        </div>
        {!pagePath && <Footer />}
      </div>
      <Setting />
    <ScrollToTop />
    </>
  );
};

export default Markup;
